import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'selectorLetters'})
export class SelectorLettersPipe implements PipeTransform {
	public transform(text: any, search): string {
		if (typeof text !== 'string') {
			text = String(text);
		}

		if (search && text) {
			let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
			pattern = pattern.split(' ').filter((t) => {
				return t.length > 0;
			}).join('|');
			const regex = new RegExp(pattern, 'gi');

			return text.replace(regex, (match) => `
					<span class="g-selected-letters">${match}</span>
			`);
		} else {
			return text;
		}
	}
}
