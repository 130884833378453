import {NgModule} from '@angular/core';

import {SearchComponent} from '@shared/ui-components/search/search.component';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';

@NgModule({
	declarations: [SearchComponent],
	exports: [SearchComponent],
	imports: [
		MatInputModule,
		FormsModule,
		MatIconModule,
		CommonModule,
		StopPropagationModule
	]
})
export class SearchModule {
}
