import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IFlatTree, ITree } from '@shared/models/tree';
import { Observable, of } from 'rxjs';
import { ConfigService } from '@shared/services/config.service';
import { ApiRepository } from '@shared/repositories/api.repository';
import { map, tap } from 'rxjs/operators';
import { ILocationTree } from '@shared/models/location-tree';
import { AccessSelector } from '@store/accesses-store/access.selector';
import { IPermissions } from '@store/types';

export interface ILocationState {
	unitLocations: ITree;
	unitLocationList: IFlatTree[];
	unitLocationMap: Map<number, IFlatTree>
}

const DefaultLocationState: ILocationState = {
	unitLocations: null,
	unitLocationList: [],
	unitLocationMap: null
}

@StoreConfig({ name: 'locationStore' })
@Injectable({ providedIn: 'root' })
export class LocationStore extends Store<ILocationState> {
	constructor(
		private _configService: ConfigService,
		private _apiRepository: ApiRepository,
	) {
		super(DefaultLocationState);
	}

	init(permission: IPermissions): Observable<ILocationState> {
		const config = this._configService.getConfig([
			'rootLocationId',
			'ascueRootLocationId',
		]);

		const locationRequest$ = config['rootLocationId']
			? this._apiRepository.getLocationTree(config['rootLocationId'])
			: of(null);

		return locationRequest$.pipe(
			map(res => {
				const data: ILocationState = this.mappingLocationData(res, permission);

				this.update(data);

				return data;
			})
		)
	}

	private mappingLocationData(res: ILocationTree, permission: IPermissions): ILocationState {
		const locationTree = this.addPermissionToLocationTree(res, permission.locations);
		const unitLocationList = this.getFlatLocationsList(locationTree);
		const unitLocationMap = new Map();

		unitLocationList.forEach((location) => unitLocationMap.set(location.id, location));

		return {
			unitLocations: locationTree,
			unitLocationList: unitLocationList,
			unitLocationMap: unitLocationMap,
		}
	}

	private addPermissionToLocationTree(locationTree: ILocationTree, permissions: number[]): ILocationTree {
		locationTree.accessAllow = permissions.includes(locationTree.id);

		if (locationTree.children) {
			locationTree.children.forEach(node =>
				this.addPermissionToLocationTree(node, permissions)
			);
		}

		return locationTree;
	}

	public getFlatLocationsList(tree: ITree): IFlatTree[] {
		const result: IFlatTree[] = [];

		const getFlat = (nodeList) => {
			nodeList
				.sort((a, b) => (a.name > b.name ? 1 : -1))
				.forEach(node => {
					const location = {
						name: node.name,
						description: node.description,
						id: node.id,
						selectable: !Boolean(node.children),
						firstLevel: node.parent && node.parent.id === tree.id,
						parent: node.parent,
						accessAllow: node.accessAllow,
					};

					result.push(location);

					if (node.children) {
						getFlat(node.children);
					}
				});
		};

		getFlat([tree]);

		return result;
	}
}
