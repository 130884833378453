import {Component, EventEmitter, Input, Output} from '@angular/core';
import {scaleAnimation} from '../../animations/common.animations';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	animations: [scaleAnimation],
})
export class SearchComponent {
	@Input() placeholder = 'Поиск';
	@Input() isShowIcon = true;
	@Input() isNeedClearByClosing = true;
	@Input() value = '';

	@Output() open = new EventEmitter<boolean>();
	@Output() search = new EventEmitter<string>();
	@Output() valueChange = new EventEmitter<string>();

	public changeInput(event: Event): void {
		// @ts-ignore
		this.value = event?.target?.value || '';

		this.search.emit(this.value);
		this.valueChange.emit(this.value);
	}

	public clear(): void {
		if (!this.isNeedClearByClosing) {
			return;
		}

		this.value = null;

		this.search.emit(this.value);
		this.valueChange.emit(this.value);
	}
}
