import {
	AdaptiveDirective
} from '@shared/directives/adaptive/adaptive.directive';
import {NgModule} from '@angular/core';

@NgModule({
	declarations: [AdaptiveDirective],
	exports: [AdaptiveDirective]
})
export class AdaptiveModule {}
