<div class="custom-file">
	<input
		type="file"
		#fileField
		class="custom-file-input"
		(change)="startUploading($event)"
	>
	<label class="custom-file-label">Выберите файл...</label>
</div>

<div class="row">
	<div class="g-col-lg-1">
		<div *ngIf="progressValue > 0">
			<progressbar
				[value]="progressValue"
				type="warning"
				[striped]="true"
			>{{ progressValue }}%
			</progressbar>
		</div>
	</div>
	<div class="g-col-lg-2">
		<div>
			<div *ngIf="file">
				{{ file.name }}
			</div>
			<div
				*ngIf="error"
				class="error"
			>
				{{ error.message }}
			</div>
			<div
				*ngIf="uploadResponse && uploadResponse.status === 'error'"
				class="errormsg"
			>
				{{ uploadResponse.message }}
			</div>
		</div>
	</div>
	<div
		*ngIf="file"
		class="g-col-sm-1"
	>
		<span
			class="pointer float-right"
			(click)="clearFile()"
			title="Удалить"
		>&times;</span>
	</div>
</div>
