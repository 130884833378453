<mat-form-field
	class="full-width g-unselectable"
	appearance="outline"
>
	<mat-label>{{ placeholder }}</mat-label>
	<input
		matInput
		placeholder='{{placeholder}}'
		[(ngModel)]="value"
		(input)="changeInput($event)"
	>
	<div
		class="g-relative"
		[style.width]="'22px'"
		[style.height]="'23px'"
		matSuffix
	>
		<mat-icon
			*ngIf="isShowIcon && !value"
			@scaleAnimation
			class="g-absolute"
			svgIcon="search"
		></mat-icon>

		<mat-icon
			*ngIf="value"
			@scaleAnimation
			svgIcon="close"
			class="g-pointer g-absolute"
			(click)="clear()"
		></mat-icon>
	</div>
</mat-form-field>


