import {ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';

@Directive({selector: '[appSortColumn]'})
export class SortTableDirective implements OnInit {
	@Input() data: any[];
	@Input() sortKey: any;
	@Input() keyOfKey: any;
	@Input() pathToField: string = null;

	@Output()
	change = new EventEmitter();

	private _ascSort: boolean;
	private _pathToFieldArray: string[];

	constructor(
		private _el: ElementRef,
		private _renderer: Renderer2,
		private _cdr: ChangeDetectorRef
	) {
		this._ascSort = true;
		this._pathToFieldArray = [];
	}

	public ngOnInit(): void {
		this._renderer.listen(this._el.nativeElement, 'click', event => {
			const parentNode = this._el.nativeElement.parentNode;
			const children = parentNode.parentNode.children;

			for (let i = 0; i < children.length; i++) {
				children.item(i).classList.remove('activeSort');
			}

			parentNode.classList.add('activeSort');

			if (this.pathToField) {
				this._pathToFieldArray = this.pathToField.split('.');
			}

			if (this.data) {
				this.sortArray();
			}

			this.changeDirection();

			this._cdr.detectChanges();

			this.change.emit(this.data);
		});
	}

	public changeDirection(): void {
		this._ascSort = !this._ascSort;
	}

	public sortArray(): void {
		this.data.sort((a, b) => {
			let first = this.getValueField(a);
			let second = this.getValueField(b);

			if (this.isObject(first) || this.isObject(second)) {
				console.log('Sort-table.directives: Objects cannot be sorted. Possible data models: numbers, strings, boolean, date.');
				return 0;
			}

			first = first === null ? '' : first;
			second = second === null ? '' : second;

			let item1;
			let item2;

			if (first instanceof Date && second instanceof Date) {
				item1 = first.getTime();
				item2 = second.getTime();
			} else if (typeof first === 'boolean' && typeof second === 'boolean') {
				item1 = first ? 1 : 0;
				item2 = second ? 1 : 0;
			} else if (!isNaN(+first) && !isNaN(+second)) {
				first = first === '' ? 0 : first;
				second = second === '' ? 0 : second;
				item1 = parseInt(first, 10);
				item2 = parseInt(second, 10);
			} else {
				item1 = typeof (first) === 'string' ? first.toLowerCase() : first;
				item2 = typeof (second) === 'string' ? second.toLowerCase() : second;
			}

			if (this._ascSort) {
				return this.sortFunction(item1, item2);
			} else {
				return this.sortFunction(item2, item1);
			}
		});
	}

	private getValueField(value): any {
		let field;

		if (!this.pathToField) {
			field = value[this.sortKey];

			if (this.keyOfKey) {
				field = field[this.keyOfKey];
			}
		} else {
			field = value[this._pathToFieldArray[0]];

			for (let i = 1; i < this._pathToFieldArray.length; i++) {
				field = field[this._pathToFieldArray[i]];
			}
		}

		return field;
	}

	private isObject(a): boolean {
		return (!!a) && (a.constructor === Object);
	}

	private sortFunction(a, b): 0 | 1 | -1 {
		if (a > b) {
			return 1;
		}
		if (a < b) {
			return -1;
		}

		return 0;
	}
}
