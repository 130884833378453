import {Directive, HostListener, Input, OnChanges, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
	selector: '[appAdaptive]',
})
export class AdaptiveDirective implements OnChanges {
	@Input() appAdaptive: { max?: number; min?: number, and?: boolean };
	isAdded: boolean;

	constructor(
		private _el: TemplateRef<any>,
		private _viewContainer: ViewContainerRef
	) {
	}

	public ngOnChanges(): void {
		this.checkAccess();
	}

	@HostListener('window:resize', ['$event'])
	private checkAccess(): void {
		if (!this.appAdaptive) {
			return;
		}

		const {min, max, and: additionalCondition} = this.appAdaptive;

		if (
			(
				min && min <= window.innerWidth ||
				max && max >= window.innerWidth
			) &&
			(
				additionalCondition === undefined ||
				additionalCondition === null ||
				additionalCondition
			)
		) {
			this.add();
		} else {
			this.clear();
		}
	}

	private clear(): void {
		if (!this.isAdded) {
			return;
		}

		this._viewContainer.clear();
		this.isAdded = !this.isAdded;
	}

	private add(): void {
		if (this.isAdded) {
			return;
		}

		this._viewContainer.createEmbeddedView(this._el);
		this.isAdded = !this.isAdded;
	}
}
