import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';

import {UploadRepository} from '@shared/repositories/upload.repository';
import {Observable, Subscription} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnChanges, OnDestroy {
	@Input() type: string;
	@Input() unitId?: string;
	@Input() ftpPath: string;
	@Output() finished = new EventEmitter();

	public file: File;
	public progressValue: number;
	public uploadResponse: any;
	public error: any;

	private _progress$: Observable<any>;
	private _progressSubscription: Subscription;

	constructor(
		private uploadService: UploadRepository
	) {
		this.progressValue = 0;
	}

	public ngOnChanges(): void {
		this.destroySubscribe();
		this.resetState();
	}

	public ngOnDestroy(): void {
		this.destroySubscribe();
		this.resetState();
	}

	public clearFile(): void {
		this.resetState();
		this.finished.emit(null);
	}

	public startUploading(event): void {
		this.resetState();

		this.file = event.target.files[0];

		if (event.target.files.length !== 1 || !this.file) {
			return;
		}

		switch (this.type) {
			case 'locationMap':
				this._progress$ = this.uploadService.uploadLocationMap(this.file);
				break;

			case 'unitType':
				this._progress$ = this.uploadService.uploadUnitTypeImage(
					this.file,
					this.unitId
				);
				break;

			case 'locationItem':
				this._progress$ = this.uploadService.uploadLocationImage(
					this.file, this.unitId
				);
				break;

			case 'toolType':
				this._progress$ = this.uploadService.uploadToolImage(this.file, this.unitId);
				break;

			case 'saveFTP':
				this._progress$ = this.uploadService.saveFTPFile(
					this.file, this.ftpPath, this.file.name
				);
				break;

			default:
				return console.error('Тип изображения не определен');
		}

		if (this._progress$) {
			this._progressSubscription = this._progress$
				.pipe(untilDestroyed(this))
				.subscribe(
					res => {
						if (res.path) {
							this.progressValue = 100;
							return this.finished.emit(res.path);
						}

						this.progressValue = res.message;
						this.uploadResponse = res;
					},
					err => {
						this.error = err;
					}
				);
		}
	}

	private destroySubscribe(): void {
		if (this._progressSubscription) {
			this._progressSubscription.unsubscribe();
		}
	}

	private resetState(): void {
		this.file = null;
		this.progressValue = 0;
		this.uploadResponse = null;
		this.error = null;
	}
}
