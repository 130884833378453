import {Component, Input} from '@angular/core';
import {SelectorLettersModule} from '@shared/pipes/selector-letters/selector-letters.module';

@Component({
	selector: 'app-selector-letters',
	templateUrl: './selector-letters.component.html',
	standalone: true,
	imports: [
		SelectorLettersModule
	]
})
export class SelectorLettersComponent {
	@Input() value!: string;
	@Input() searchString?: string;
}
