import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { UploadComponent } from '@shared/ui-components/upload/upload.component';
import { UploadRepository } from '@shared/repositories/upload.repository';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [UploadComponent],
	imports: [CommonModule, ProgressbarModule, MatInputModule],
	exports: [UploadComponent],
	providers: [UploadRepository],
})
export class UploadModule {
}
