import {Injectable} from '@angular/core';
import {HttpEventType, HttpRequest} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BaseRepository} from './base-repository';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UploadRepository extends BaseRepository {
	private upload(file: File, url: string, path?: string, fileName?: string): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('file', file);

		if (path) {
			formData.append('path', path);
		}
		if (fileName) {
			formData.append('fileName', fileName);
		}

		const req = new HttpRequest(
			'put',
			this._config['javaApiUrl'] + url,
			formData,
			{
				reportProgress: true
			}
		);

		return this._http.request(req)
			.pipe(
				map(event => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							const progress = Math.round(
								(100 * event.loaded) / event.total
							);

							return {status: 'progress', message: progress};

						case HttpEventType.Response:
							return event.body;

						default:
							return `Unhandled event: ${event.type}`;
					}
				})
			);
	}

	public uploadLocationMap(file: File): Observable<any> {
		const url = '/adm/locationMap/imageUpload';

		return this.upload(file, url);
	}

	public uploadUnitTypeImage(file: File, unitId): Observable<any> {
		const url = `/admin/unitType/${unitId}/imageUpload`;

		return this.upload(file, url);
	}

	public uploadLocationImage(file: File, locationId): Observable<any> {
		const url = `/admin/location/${locationId}/imageUpload`;

		return this.upload(file, url);
	}

	public uploadToolImage(file: File, toolId): Observable<any> {
		const url = `/sd/tool/${toolId}/imageUpload`;

		return this.upload(file, url);
	}

	public saveFTPFile(file, path: string, fileName: string): Observable<any> {
		const url = `/ftp/directory/save`;

		return this.upload(file, url, path, fileName);
	}
}
